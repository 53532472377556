<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-sheet class="pa-8">
      <h1>{{ $t("rules-title") }}</h1>
      <p>{{ $t("rules-text") }}</p>
      <ol>
        <li v-for="ruleSlug in ruleSlugs" :key="ruleSlug" class="my-2">
          {{ $t(ruleSlug) }}
        </li>
      </ol>
    </v-sheet>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "rules",
  components: {
    Breadcrumbs
  },
  data: () => ({
    ruleSlugs: [
      "rule-1",
      "rule-2",
      "rule-3",
      "rule-4",
      "rule-5",
      "rule-6",
      "rule-7",
      "rule-8",
      "rule-9",
      "rule-10"
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("group"),
          disabled: false,
          href: "/group"
        },
        {
          text: this.$t("rules"),
          disabled: true
        }
      ];
    }
  }
};
</script>
